@use '@/styles/variables.scss';

.insuredContainer {
  display: flex;
  align-items: center;
}

.insuredText {
  margin-right: 1rem;
}

.insuredTextDisabled {
  @extend .insuredText;
  opacity: 0.5;
}

.insuredTextInvalid {
  @extend .insuredText;
  padding-bottom: 1.8rem;
}

.header {
  display: flex;
  box-shadow: 0 1px 8px 0 rgba(33, 33, 33, 0.15);
  height: variables.$height-header;
  position: fixed;
  background: #fff;
  top: 0;
  right: 0;
  width: calc(100% - #{variables.$sidebar-width-lg});
  z-index: variables.$z-index-8;
  transition: width 0.3s ease-in-out;
}
.collapseHeader {
  width: calc(100% - #{variables.$sidebar-width-collapsed});
}

.headerLeft {
  flex: 1;
  max-width: 350px;
  text-align: left;
  display: flex;
  align-items: center;
}

.headerRight {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.insuredSelect {
  align-items: center;
  flex-grow: 1;
}

.dropdownContainer {
  position: fixed;
  z-index: variables.$z-index-8;
  top: 12px;
  right: 20px;

  display: flex;
  align-items: center;
}

.insuredBranding {
  top: 12px + variables.$insured-header-bar-size;
}

.expandedInsuredBranding {
  top: 12px + variables.$expanded-insured-header-bar-size;
}

.typeahead {
  width: 100%;
  min-width: 500px;
}

.insuredName {
  margin-top: 12px;
  color: variables.$color-readable-dark;
}

button.dropdownToggle {
  border: 0 !important;

  &[aria-expanded='true'],
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: none !important;
  }
}

div.dropdownMenu {
  left: auto;
  right: auto;

  &:global(.dropdown-menu.show) {
    width: auto !important;
    right: 7px !important;
    top: 0 !important;
    inset: 0px auto auto -14px !important;
  }
}

@media screen and (max-width: variables.$screen-md-max) {
  .header {
    width: calc(100% - #{variables.$sidebar-width-sm});
  }
  .collapseHeader {
    width: calc(100% - #{variables.$sidebar-width-collapsed});
  }
}

@media screen and (min-width: variables.$screen-lg-min) and (max-width: variables.$screen-lg-max) {
  .header {
    width: calc(100% - #{variables.$sidebar-width-md});
  }
  .collapseHeader {
    width: calc(100% - #{variables.$sidebar-width-collapsed});
  }
}
