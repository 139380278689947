@use "@/styles/variables.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  pointer-events: none;
}

button.button {
  border: 1px solid variables.$color-border;
  padding: 4px 12px;
  height: variables.$height-input;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: variables.$color-content;
  pointer-events: auto;
  border-radius: 0;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background: variables.$color-content;
    border-color: variables.$color-border-hover;
    box-shadow: variables.$shadow-button;
    border-radius: 0 !important;

    .labelContainer,
    .iconLeftContainer svg,
    .iconRightContainer svg {
      color: variables.$color-border-hover;
      fill: variables.$color-border-hover;
    }
  }
}

.iconLeftContainer svg,
.iconRightContainer svg {
  color: variables.$color-button-svg;
  fill: variables.$color-button-svg;
}

button.button,
.labelContainer,
.iconLeftContainer svg,
.iconRightContainer svg {
  transition: background-color 330ms variables.$ease-expo, color 330ms variables.$ease-expo,
    border-color 330ms variables.$ease-expo;
}

.iconLeftContainer,
.iconRightContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconLeftContainer {
  margin-right: 10px;
}
.iconRightContainer {
  margin-left: 10px;
}

.labelContainer {
  color: variables.$color-readable-dark;
  font-size: variables.$fontsize-button;
  font-weight: variables.$fontweight-medium;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin: 0px auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > :global(div.spinner-border) {
    margin: 0px auto;
  }
}
.iconLeftContainer svg,
.iconRightContainer svg {
  color: variables.$color-button-svg;
  fill: variables.$color-button-svg;
  font-size: variables.$fontsize-normal;
}

button.createButton {
  background-color: variables.$color-orange !important;
  border-color: variables.$color-orange !important;
  flex-shrink: 0;

  svg,
  .createButtonLabel {
    color: #fff;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: variables.$color-orange-hover !important;
    border-color: variables.$color-orange-hover !important;

    svg,
    .createButtonLabel {
      color: #fff !important;
    }
  }
}

button.primaryButton {
  background-color: variables.$color-primary !important;
  border-color: variables.$color-primary !important;
  flex-shrink: 0;

  svg,
  .primaryButtonLabel {
    color: #fff;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: variables.$color-primary-hover !important;
    border-color: variables.$color-primary-hover !important;

    svg,
    .primaryButtonLabel {
      color: #fff !important;
    }
  }
}

button.dangerButton {
  background-color: variables.$color-danger !important;
  border-color: variables.$color-danger !important;
  flex-shrink: 0;

  svg,
  .dangerButtonLabel {
    color: #fff;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: variables.$color-danger-hover !important;
    border-color: variables.$color-danger-hover !important;

    svg,
    .dangerButtonLabel {
      color: #fff !important;
    }
  }
}

.link {
  font-size: variables.$fontsize-button;
  font-weight: variables.$fontweight-bold;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  text-decoration: none;
  display: flex;
  flex-direction: row;

  .iconLeftContainer {
    margin-right: 7px;
  }
  .iconRightContainer {
    margin-left: 7px;
  }

  .iconLeftContainer svg,
  .iconRightContainer svg {
    color: variables.$color-primary;
    fill: variables.$color-primary;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    .iconLeftContainer svg,
    .iconRightContainer svg {
      color: variables.$color-primary-hover;
      fill: variables.$color-primary-hover;
    }
  }
}

.previewButtonLabel {
  color: #2d70bd;
  
}

.previewButtonIcon {
  margin-right: 10px;
  color: #2d70bd;
}