@use "@/styles/variables.scss";

.lobActionsDropdown {
  width: 225px;

  :global(.dropdown-menu) {
    width: 225px;
  }
}

.active {
  background-color: variables.$color-selected;
}
