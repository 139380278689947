@use '@/styles/variables.scss';

.container {
  width: 100%;
  height: 48px;
  cursor: pointer;
}

.link {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  border-left: 4px solid transparent;

  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
    color: variables.$color-content;
  }

  &.insuredBranding {
    color: variables.$color-readable-dark;
  }

  &.active {
    border-left-color: variables.$color-orange !important;
    background-color: variables.$color-sidebar-hover !important;

    &.insuredBranding {
      border-left-color: variables.$color-readable-dark !important;
      background-color: variables.$color-content !important;
    }
  }
}

.iconContainer {
  width: 19px;
  min-width: 19px;
  height: 24px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    object-fit: contain;
    width: 100% !important;
  }
}
.collapsedIconContainer {
  width: variables.$sidebar-width-collapsed;
}

.labelContainer {
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 14px;
  line-height: 1.1;
  min-width: 82%;
  position: relative;
}

.subLinks {
  color: #fff;
  font-size: 12px;
  padding: 3px 0 3px 48px;
  line-height: 25px;
  width: 100%;
  border-left: 4px solid transparent;
  &:hover {
    text-decoration: none !important;
    color: #fb7124;
    background-color: #233d67;
  }

  &.insuredBranding {
    color: variables.$color-readable-dark;
    &:hover {
      background-color: variables.$color-content;
      color: variables.$color-readable-dark;
      font-weight: 600;
    }
    &:hover {
    }
  }

  &.activeSubLinks {
    background-color: #233d67;
    &:hover {
      color: #fb7124;
      text-decoration: none;
    }

    &.insuredBranding {
      background-color: variables.$color-content;
      &:hover {
        color: variables.$color-readable-dark;
        font-weight: 600;
      }
      &.selectedSubLink:hover {
        font-weight: 800;
      }
    }
  }
}

.selectedSubLink {
  font-weight: 800;
  color: #fb7124;
}

.svgComponent {
  width: 12px;
  height: 11px;
  margin-left: 3px;
  right: 16px;
  position: absolute;
  top: 4px;
}

.label,
.icon {
  color: variables.$color-readable-light;
  &.insuredBranding {
    color: variables.$color-readable-dark;
  }
}

.label {
  width: 100%;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1.1;
}

@media screen and (max-width: variables.$screen-md-max) {
  .labelContainer {
    margin-left: 12px;
  }

  .iconContainer {
    width: 15px;
    height: 17px;
    margin-left: 5px;
    svg {
      max-height: 17px;
    }
  }
  .collapsedIconContainer {
    width: variables.$sidebar-width-collapsed;
  }

  .label {
    font-size: 11px;
  }
}

@media screen and (min-width: variables.$screen-lg-min) and (max-width: variables.$screen-lg-max) {
  .iconContainer {
    width: 16px;
    height: 18px;
    margin-left: 6px;

    svg {
      max-height: 18px;
    }
  }
  .collapsedIconContainer {
    width: variables.$sidebar-width-collapsed;
  }

  .label {
    font-size: 12px;
  }
}
