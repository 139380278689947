@use "@/styles/variables.scss";


#mainContainer {
  margin-bottom:24px;
  margin-right: 20px;
  margin-left:20px;
  background-color: white;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 90vh ;
}

.questionMarkContainer{
  display: flex;
  justify-content: center;
  margin-top: 100;
  margin-bottom:  50;
}

.questionMark {
  color: #C11B1B;
  font-size: 210px;
  border-radius: 9999px;
  background-color: #F6E6E3;
  font-family: variables.$font-title;
  width: 285px;
  height: 285px;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.errorMessage {
  display: flex;
  justify-content: center;
  margin-top: 55px;
  font-size: 28px;
  font-weight: 700;
  font-family: variables.$font-title;
  line-height: 34.19px;
}

.refreshMessage{
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 36px;
  color: variables.$color-ch-gray;
  font-size: 16px;
  font-weight: 400;
  font-family: variables.$font-title;
  line-height: 19.09px;

}
.refereshButtonContainer{
  display: flex;
  justify-content: center;
}

.refereshButton{
  border-width: 1px;
  border-style: solid;
  border-color:#CDCCCD ;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: variables.$font-title;
  line-height: 14.65px;
}