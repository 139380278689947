$color-ch-blue: #2f86c0;
$color-ch-blue-hover: #57aef2;
$color-ch-yellow: #fbc53e;
$color-ch-gray: #808080;
$color-primary: #4f74a3;
$color-primary-hover: #315480;
$color-orange: #fb7124;
$color-orange-hover: #da5c16;
$color-readable-dark: #4f4f4f;
$color-readable-light: #f5f5f5;
$color-sidebar: #0d2956;
$color-sidebar-hover: #233d67;
$color-selected: #fffbec;
$color-selected-hover: #eeeeee;
$color-border: #cdcdcd;
$color-border-hover: #5f5f5f;
$color-background: #f9f7f7;
$color-iframe: #f1f3f5;
$color-content: #fff;
$color-button-svg: scale-color($color-readable-dark, $lightness: +30%);
$color-status-progress: #29bae8;
$color-status-overridden: #f27935;
$color-status-default: #a9a9a9;
$color-status-active: #0fe24a;
$color-status-expiring: #ff8c00;
$color-status-win: #9d8dfa;
$color-success: #40b68f;
$color-danger: #dc3545;
$color-danger-hover: #b82b3a;
$color-dim: #ebeff5;

$font-title: 'Helvetica Neue', sans-serif;
$font-body: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$fontsize-small: 11px;
$fontsize-button: 12px;
$fontsize-normal: 14px;
$fontsize-large: 18px;

$fontweight-regular: 400;
$fontweight-medium: 500;
$fontweight-semibold: 600;
$fontweight-bold: 700;

$shadow-button: 0 3px 8px 0 rgba(21, 21, 21, 0.1);
$shadow-modal: 0 6px 16px 0 rgba(21, 21, 21, 0.2);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$height-input: 38px;
$height-header: 80px;
$height-footer: 42px;
$transition-menu: 240ms;
$sidebar-width-sm: 180px;
$sidebar-width-md: 210px;
$sidebar-width-lg: 240px;
$sidebar-width-collapsed: 65px;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$insured-header-bar-size: 25px;

$expanded-insured-header-bar-size: 75px;
