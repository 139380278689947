@use "@/styles/variables.scss";

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: space-evenly;
  width: 100%;
  margin: 10px;
}

.header {
  margin: 10px;
  display: flex;
}

.title {
  width: 90%;
}

.saveButton {
  width: 100%;
}

.saveButtonContainer {
  margin-left: 0.5rem;
}

.smallItem {
  flex: 1;
}

.mediumItem {
  flex: 1.5;
}

.fullLengthItem {
  width: 100%;
  padding-bottom: 10px;
}

.indicatorMapping {
  margin-top: 10px;
}

.indicatorWarningIcon {
  float: left;
  margin: 20px 5px;

  svg > path {
    fill: variables.$color-ch-yellow;
    cursor: pointer;
  }
}

.freeformWarningIcon {
  margin: 5px 6px 0px 3px;

  svg > path {
    fill: variables.$color-ch-yellow;
    cursor: pointer;
  }
}

.individualMapping {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.nameField {
  display: flex;
  flex: 1;
  margin-right: 5px;
}

.valueField {
  flex: 1;
}

.descField {
  width: 100%;
}

.overflowButton {
  padding-top: 20px !important;
}

.removeButton {
  padding-left: 10px;
}

.multiFieldContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  margin-top: 10px;
  width: 100%;

  div {
    flex: 1;
  }
}

.switchContainer {
  display: flex;
  max-width: 400px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.switchAmsValue {
  width: 100%;
}

.switchLabel {
  width: 50%;
  margin: auto;
  padding-right: 10px;
}

.switchCheckbox {
 display: flex;
 width: 40%;
}

.checkboxLabel {
  cursor: default;
}

.error {
  margin-top: 16px;
  color: variables.$color-danger;
}

.errorIcon {
  float: left;
  flex: 0 !important;
  margin: 30px 5px;
  svg > path {
    fill: variables.$color-danger;
    cursor: pointer;
  }
}

.freeformErrorIcon {
  margin: 5px 6px 0px 3px;

  svg > path {
    fill: variables.$color-danger;
    cursor: pointer;
  }
}

.headerText {
  width: 100%;
  padding-top: 10px;
  font-weight: 500;
}

.compoundFieldHeading {
  width: 100%;
  padding-top: 10px;
  font-weight: 700 !important;
  font-size: 1rem !important;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0;
}

.editIcon {
  margin-right: 0.4rem;
}

.editButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.schedulesDropDown{
  padding-bottom: 10px;
}

.schedulesContainer {
  width: 100%;
}

.schedulesTable {
  width: 100%;
}

.schedulesCell {
  padding-bottom: 10px;
}

.schedulesEndCell {
  display: flex;  
  width: 100%;
}

.freeFormAmsLabel {
  width: 100%;
}
