@use "@/styles/variables.scss";
@use "@ag-grid-community/styles" as ag;

@include ag.grid-styles((
  theme: custom-react,
  extend-theme: alpine,
  odd-row-background-color: null,
  checkbox-checked-color: #2661ad,
  header-background-color: #dddddd,
  borders: false,
));

.grid-pill {
  height: 25px;
  min-width: 120px;
  text-align: center;
  padding: 8px;
  margin-top: 10px;
  text-transform: uppercase;
  border-radius: 25px;
  font-weight: bold;
  font-size: 10px;
}

.grid-pill-content {
  margin-top: -5px;
}

.grid-center-cell {
  text-align: center;
}

.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-tooltip {
  margin: 0;
  position: absolute;
  border-radius: 10px;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
  background-color: variables.$color-sidebar;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}
