@use "@/styles/variables.scss";

.actionMenuColumn {
  overflow: visible;
  border: 0 !important;

  & > div {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    padding-left: 7px;
    padding-right: 7px;
  }
}

button.actionButton {
  width: auto;
  min-width: 46px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionMenuSvg {
  width: 20px;
  height: 14px;
}

.actionMenu {
  min-width: 90px;
  z-index: variables.$z-index-5;
}

.actionMenuToggle {
  padding-left: 8px;
  padding-right: 8px;

  svg {
    width: 10px;
  }
}

.actionMenuCaret {
  margin-left: 5px;
}

.dateValue,
.dateDiff {
  line-height: 1.1;
}
.dateDiff {
  color: variables.$color-orange;
}

.booleanIconContainer {
  width: 100%;
  text-align: center;
  margin: auto;
}

.statusContainer {
  border: 1px solid variables.$color-status-default;
  padding: 5px 10px;
  width: 100px;
  font-size: variables.$fontsize-small;
  text-transform: uppercase;
}

.fileCabinetStatusContainer {
  display: flex;
  padding: 0px 5px;
  width: 120px;
}

.inBulkIcon {
  margin: auto;
  cursor: pointer;
  color: variables.$color-primary;
}

.inBulkTooltip {
  & > [class$='arrow'] {
    &::before {
      border-left-color: variables.$color-sidebar;
    }
  }

  & > [class$='inner'] {
    background-color: variables.$color-sidebar;
    color: whitesmoke;
  }
}

.largeContent {
  height: auto;
  white-space: nowrap;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 20px;
  max-height: 66px;
  overflow: hidden;
  margin: auto;
  text-overflow: ellipsis;
}
