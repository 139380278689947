.checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 5px;
}

.inner {
  margin-bottom: 0;

  &.disabled,
  &.disabled .label {
    cursor: not-allowed;
  }
}

.input {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;

  &:disabled,
  &:disabled .label {
    cursor: not-allowed;
  }
}

.label {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}
