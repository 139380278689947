@use "@/styles/variables.scss";
// Needed for grid gutter width value
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

.modalExtraLarge {
  min-width: 85%;
}

.modalLarge {
  min-width: 70%;
}

.modalMedium {
  min-width: 50%;
}

.dropdownToggle {
  color: #495057;
}

.truncate {
  // Am I safe removing this?
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caret {
  margin-left: 10px;
  font-size: variables.$fontsize-normal;
  path {
    fill: variables.$color-button-svg;
  }
}

.buttonReset {
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  text-align: inherit;
  color: inherit;
  font: inherit;
  outline: none;
  border: none;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.pageTitle {
  color: variables.$color-readable-dark;
  font-family: variables.$font-title;
  font-weight: variables.$fontweight-regular;
  letter-spacing: 0.2px;
  font-size: 24px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 18px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.border {
  border: 1px solid variables.$color-border;
}
.borderTop {
  border-top: 1px solid variables.$color-border;
}
.borderBottom {
  border-bottom: 1px solid variables.$color-border;
}

.split {
  background-color: variables.$color-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  :global(.gutter) {
    cursor: pointer !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.splitRight {
  min-height: 80vh;
}
.splitLeft,
.splitRight {
  :global(.nav-tabs) {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
  :global(.tab-pane),
  :global(.tab-content) {
    border: none;
  }

  :global(.tab-pane) {
    overflow-y: scroll;
  }
}

.inputContainer {
  margin-bottom: 18px;
  position: relative;
  width: 100%;
}

.formActions {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.openAnimation {
  transition: transform variables.$transition-menu variables.$ease-circ,
    opacity variables.$transition-menu variables.$ease-circ;
  opacity: 0 !important;
  transform: translateY(-30px);

  &.entering,
  &.entered {
    opacity: 1 !important;
    transform: translateY(0px);
  }
  &.exiting {
    transition: transform variables.$transition-menu variables.$ease-circ,
      opacity variables.$transition-menu variables.$ease-circ;
    opacity: 0 !important;
    transform: translateY(-6px);
  }
  &.exited {
    opacity: 0 !important;
    transform: translateY(-30px);
    pointer-events: none !important;
    user-select: none;

    button {
      pointer-events: none !important;
    }
  }
}

.hideFloatingFilter {
  :global {
    .ag-header-row-floating-filter {
      display: none !important;
    }
  }
}

.headerBg {
  background-color: variables.$color-background;
  padding-top: 0;
}
.contentBg {
  background-color: variables.$color-content;
}
.pt0 {
  padding-top: 0 !important;
}

.link {
  text-decoration: underline;
  color: variables.$color-primary;

  &:link,
  &:visited {
    color: variables.$color-primary;
  }

  &:active,
  &:hover {
    color: variables.$color-primary-hover;
  }
}

.danger {
  color: variables.$color-danger !important;
}

.warning {
  color: variables.$color-orange !important;
}
.gutterSmall {
  $custom-grid-gutter-width: $grid-gutter-width / 2;

  margin-left: -$custom-grid-gutter-width / 2;
  margin-right: -$custom-grid-gutter-width / 2;

  :global {
    .row {
      margin-left: -$custom-grid-gutter-width / 2;
      margin-right: -$custom-grid-gutter-width / 2;
    }
  }

  [class^='col-'],
  [class*=' col-'] {
    padding-left: $custom-grid-gutter-width / 2;
    padding-right: $custom-grid-gutter-width / 2;
  }
}

.gridButton {
  float: right;
  z-index: 2;
}

.clickable {
  &:focus:not(:global(.focus-visible)) {
    outline: none;
  }
}

.buttonMargin {
  margin-left: 10px;
}

.checkboxUnselected{
  color: variables.$color-ch-gray;
}

.checkboxSelected{
  color: variables.$color-primary;
}

@media screen and (max-width: variables.$screen-md-max) {
  .tabContent {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (max-width: variables.$screen-sm-max) {
  .tabContent,
  .layoutPadding {
    padding-left: 14px;
    padding-right: 14px;
  }
}

@media screen and (min-width: variables.$screen-md-min) and (max-width: variables.$screen-lg-max) {
  .layoutPadding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: variables.$screen-xl-min) {
  .layoutPadding {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.error {
  color: variables.$color-danger;
}
