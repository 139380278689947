@use '@/styles/variables.scss';

.outer {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.sidebarButtonHidden {
  display: none;
}

.sidebarColumn {
  background-color: variables.$color-sidebar;
  width: variables.$sidebar-width-lg;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: variables.$z-index-5;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  transition: width 0.3s ease-in-out;

  color: variables.$color-content;

  &.insuredBranding {
    top: variables.$insured-header-bar-size;
    background-color: variables.$color-selected-hover;
    color: variables.$color-readable-dark;

    & > div {
      background-color: variables.$color-selected-hover;
    }
  }

  &.expandedInsuredBranding {
    top: variables.$expanded-insured-header-bar-size;
  }
}
.collapsedSidebar {
  width: variables.$sidebar-width-collapsed;
  transition: width 0.3s ease-in-out;
}

.mainColumn {
  flex: 1;
  padding-left: variables.$sidebar-width-lg;
  background-color: variables.$color-background;
  z-index: variables.$z-index-4;
  align-self: stretch;
  width: 100%;
  transition: padding-left 0.3s ease-in-out;

  &.insuredBranding > nav {
    top: variables.$insured-header-bar-size;
  }

  &.expandedInsuredBranding > nav {
    top: variables.$expanded-insured-header-bar-size;
  }
}
.collapsedMainColumn {
  padding-left: variables.$sidebar-width-collapsed;
}

.inner {
  padding-top: calc(#{variables.$height-header} + 18px);
  align-self: stretch;

  &.insuredBranding {
    padding-top: variables.$height-header + 18px +
      variables.$insured-header-bar-size;
  }
  &.expandedInsuredBranding {
    padding-top: variables.$height-header + 18px +
      variables.$expanded-insured-header-bar-size;
  }
}

.profileLink {
  float: right;
  cursor: pointer;
  margin-right: 50px;
}

.insuredName {
  white-space: nowrap;
  width: 400px;
}

.insuredHeaderBar {
  height: variables.$insured-header-bar-size;
  z-index: 500;
}

.expandedInsuredHeaderBar {
  height: variables.$expanded-insured-header-bar-size;
}

.insuredLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insuredLogo {
  width: 700px;
  text-align: center;
  margin: 0 auto;
  margin-top: 2px;
}

.navButton {
  position: fixed;
  z-index: 999 !important;
  top: 40px;
  left: calc(variables.$sidebar-width-lg - 12px);
  transform: rotate(-180deg);
  transition: left 0.2s ease-in-out, transform 0.2s ease-in-out;
  cursor: pointer;
}
.collapseNavButton {
  left: calc(variables.$sidebar-width-collapsed - 10px);
  transform: rotate(0deg);
}
.expandedInsuredNavButton {
  top: calc(40px + variables.$expanded-insured-header-bar-size);
}

@media screen and (max-width: variables.$screen-md-max) {
  .sidebarColumn {
    width: variables.$sidebar-width-sm;
    min-width: variables.$sidebar-width-sm;
  }
  .collapsedSidebar {
    width: variables.$sidebar-width-collapsed;
    min-width: variables.$sidebar-width-collapsed;
  }
  .mainColumn {
    padding-left: variables.$sidebar-width-sm;
    overflow-y: hidden;
  }
  .collapsedMainColumn {
    padding-left: variables.$sidebar-width-collapsed;
  }
  .navButton {
    left: calc(variables.$sidebar-width-sm - 12px);
  }
  .collapseNavButton {
    left: calc(variables.$sidebar-width-collapsed - 10px);
  }
}

@media screen and (min-width: variables.$screen-lg-min) and (max-width: variables.$screen-lg-max) {
  .sidebarColumn {
    width: variables.$sidebar-width-md;
    min-width: variables.$sidebar-width-md;
  }
  .collapsedSidebar {
    width: variables.$sidebar-width-collapsed;
    min-width: variables.$sidebar-width-collapsed;
  }
  .mainColumn {
    padding-left: variables.$sidebar-width-md;
  }
  .collapsedMainColumn {
    padding-left: variables.$sidebar-width-collapsed;
  }
  .navButton {
    left: calc(variables.$sidebar-width-md - 10px);
  }
  .collapseNavButton {
    left: calc(variables.$sidebar-width-collapsed - 10px);
  }
}
