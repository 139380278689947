@use "@/styles/variables.scss";

.mappingDropdown {
  min-width: 225px;

  :global(.dropdown-menu) {
    min-width: 225px;
  }
}

.newTag {
  border-radius: 5px;
  background-color: variables.$color-danger;
  color: white;
  padding: 0px 10px;
  margin-right: 10px;
}

.dropdownElement {
  display: flex;
  flex-direction: row;
}

.editSpace{
  min-width: 40px;
  display: flex;
  justify-content: center;
}
