@use "@/styles/variables.scss";

.footer {
  width: 100%;
  display: flex;
  background-color: variables.$color-sidebar;
  padding: 0;
  margin: 0;
  height: variables.$height-footer;

  &.insuredBranding {
    background-color: variables.$color-selected-hover;
  }
}

.footerLeft {
  flex: 1;
}
.footerMiddle {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerRight {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  margin-right: 100px;

  button {
    border-top: 0;
    border-radius: 0 0 25% 25%;
    border-color: rgb(42, 81, 117);
    background-color: variables.$color-sidebar;
    margin: 0 5px 5px 5px;
  }

  &.insuredBranding {
    button {
      background-color: variables.$color-selected-hover;
      border-color: darken(variables.$color-selected-hover, 10%);
      color: variables.$color-readable-dark;

      &:active {
        background-color: lighten(variables.$color-selected-hover, 10%) !important;
        border-color: darken(variables.$color-selected-hover, 10%) !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem lighten(variables.$color-selected-hover, 10%) !important;
      }
    }
  }
}

.copyLabel {
  color: #fff;
  &.insuredBranding {
    color: variables.$color-readable-dark;
  }
}

@media screen and (max-width: variables.$screen-md-max) {
  .footer {
    z-index: variables.$z-index-6;
    position: relative;
  }
  .footerLeft {
    display: none;
  }
  .footerMiddle {
    flex: 1;
    width: auto;
    min-width: 240px;
    padding-left: variables.$sidebar-width-sm;
    text-align: left;
    margin-right: 24px;
  }
  .footerRight {
    width: 280px;
  }
}

@media screen and (max-width: variables.$screen-sm-max) {
  .footerMiddle {
    padding-left: 10px;
  }
}
