@use "@/styles/variables.scss";

.toast {
  background-color: red;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 48px;
  min-height: 48px;
  padding-bottom: 2px;

  &.success {
    background-color: #e4fcf3;
    border: 1px solid #00b070;
  }

  &.warning {
    background-color: #fcf5df;
    border: 1px solid #e9cb0a;
  }

  &.error {
    background-color: #f7e3e3;
    border: 1px solid #c42727;
  }

  &.info {
    background-color: #d7dde6;
    border: 1px solid variables.$color-primary;
  }

  :global {
    .Toastify__toast-container {
      z-index: variables.$z-index-6;
    }
    .Toastify__toast-icon {
      width: 30px;

      svg {
        max-width: 22px;
        margin: 0px auto;
      }
    }
    .Toastify__close-button {
      position: absolute;
      top: 7px;
      right: 9px;
    }
    .Toastify__close-button {
      svg path {
        fill: variables.$color-button-svg;
      }
      &:hover {
        svg path {
          fill: variables.$color-border-hover;
        }
      }
    }

    .Toastify__progress-bar--success {
      background-color: #00b070;
    }
    .Toastify__progress-bar--warning {
      background-color: #e9cb0a;
    }
    .Toastify__progress-bar--error {
      background-color: #c42727;
    }
    .Toastify__progress-bar--info {
      background-color: variables.$color-primary;
    }
  }
}

.body {
  color: variables.$color-readable-dark;
  font-weight: variables.$fontweight-medium;
  font-size: variables.$fontsize-normal;
  line-height: 1.2;
  max-height: 44px;
  overflow: hidden;
  padding-right: 30px;

  & > div:last-of-type {
    max-height: 34px;
    overflow: hidden;
  }
}
