@use '@/styles/variables.scss';
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a,
button {
  &:focus-visible {
    outline: auto 2px Highlight;
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

input[type='checkbox'] {
  cursor: pointer;
}

#__next {
  height: 100%;
}

.Toastify__toast-container--top-right {
  // top: calc(#{variables.$height-header} + 13px);
}

.rpv-default-layout__toolbar {
  min-height: 2.5rem;
  height: auto;
}

.rpv-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.rpv-default-layout__sidebar-headers {
  padding-top: 1.6rem;
}

.rpv-core__inner-page {
  top: 1.6rem;
}

.rpv-highlight__selected-end {
  display: none;
}