@use "@/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
}

.label {
  display: inline-block;
}

.border {
  border-color: variables.$color-border;
  border-style: solid;
  border-radius: 0;
  border-width: 1px;
  padding: 3px 2px;
  background-color: variables.$color-content;
  transition: all 110ms variables.$ease-expo;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.invalid {
    border-color: variables.$color-danger;
  }

  svg path {
    fill: variables.$color-button-svg;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &.focused:not(.disabled),
  &:focus:not(.disabled),
  &:active:not(.disabled),
  &:hover:not(.disabled) {
    border-color: variables.$color-border-hover;
    box-shadow: variables.$shadow-button;

    svg path {
      fill: variables.$color-border-hover;
    }

    &.invalid {
      border-color: variables.$color-danger;
    }
  }

  &.disabled textarea {
    cursor: not-allowed;
  }

  &.hasValue {
    background-color: variables.$color-content;
  }
}

.input {
  flex: 1;
  border: 0;
  outline: 0;
  width: 100%;
  height: calc(#{variables.$height-input} - 4px);
  background: transparent;
  font-size: variables.$fontsize-normal;
  font-family: variables.$font-body;
  color: variables.$color-readable-dark;
  line-height: 1;
  letter-spacing: 0.2px;
  box-shadow: none !important;
  background-image: none !important;
}

.error {
  margin-top: 8px;
  color: variables.$color-danger;
}
