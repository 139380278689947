@use "@/styles/variables.scss";

.avatar {
  position: relative;
  width: 32px;
  height: 32px;
  background: #fccdc6;
  font-size: variables.$fontsize-normal;
  font-weight: variables.$fontweight-medium;
  color: variables.$color-readable-dark;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center; 
}