@use "@/styles/variables.scss";

.container {
  svg path {
    fill: variables.$color-button-svg;
  }
}

.filterButton {
  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    svg path {
      fill: variables.$color-border-hover;
    }
  }
}

.filterOpen {
  background: variables.$color-content !important;
  border-color: variables.$color-border-hover !important;
  box-shadow: variables.$shadow-button !important;

  svg {
    path {
      fill: variables.$color-readable-dark;
    }
  }
}

.input {
  padding-left: 0;
}

.arrowSvg {
  width: 12px;
  height: 11px;
  margin-left: 8px;
}

.searchSvg {
  width: 28px;
  height: 18px;
  margin-right: 4px;
}
