@use "@/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.dropdown {
  overflow: visible;
  background: transparent;
  display: inline-block;
  width: 100%;

  :after {
    display: none;
  }

  :global {
    .dropdown-menu.show {
      width: auto;
      max-height: 400px;
      overflow-y: auto;
      visibility: visible;
    }
    .dropdown-item {
      color: variables.$color-readable-dark !important;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: variables.$color-selected-hover;
    }
  }
}

.toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  color: variables.$color-readable-dark !important;
  background-color: variables.$color-content !important;
  border: 1px solid variables.$color-border !important;
  border-radius: 0 !important;
  height: variables.$height-input;
  outline: none !important;
  box-shadow: none !important;
  font-size: variables.$fontsize-button;
  font-weight: variables.$fontweight-medium;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 0.25px;
  max-width: 100%;
  width: 100%;

  &:disabled {
    opacity: 0.5;
  }

  &:focus-visible:not(:disabled) {
    outline: -webkit-focus-ring-color auto 1px !important;
  }

  &[aria-expanded="true"],
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: variables.$color-border-hover !important;
    box-shadow: variables.$shadow-button !important;
    background-color: variables.$color-content !important;

    .childContainer,
    .childContainer svg {
      color: variables.$color-border-hover;
    }
    path {
      fill: variables.$color-border-hover;
    }
  }
}

.menu {
  min-width: 100%;
  box-shadow: variables.$shadow-modal;
  transition: transform 160ms variables.$ease-circ, opacity 160ms variables.$ease-circ;
  z-index: variables.$z-index-6;

  &.isClosed {
    top: -21px !important;
  }
}

.menuItem {
  line-height: 24px;
  padding: 0.3rem 1.15rem;
  font-size: variables.$fontsize-normal;
  color: variables.$color-readable-dark;
  letter-spacing: 0.25px;

  &.selected {
    background-color: variables.$color-selected !important;
  }
  &.disabled {
    background-color: #e9ecef !important;
    opacity: 0.25;
    pointer-events: auto;
    cursor: not-allowed;
  }
  &.label {
    opacity: 0.5;
    padding: 0.2rem 0.55rem;
  }
}

.items{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.noResults {
  text-align: center;
  width: 100%;
  color: variables.$color-ch-gray;
}

.caret {
  margin-left: 10px;
  font-size: variables.$fontsize-normal;
  path {
    fill: variables.$color-button-svg;
  }
}

.dropdownOpen .caret {
  path {
    fill: variables.$color-readable-dark;
  }
}

.childContainer {
  width: 100%;
  text-align: left;
}

.childContainer,
.childContainer svg {
  color: variables.$color-readable-dark;
}


.childContainer {
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}

.arrowSvg {
  width: 12px;
  height: 11px;
  margin-bottom: 1px;
}

.label {
  display: inline-block;
}

.subLabel {
  display: inline-block;
  font-size: variables.$fontsize-small;
  color: variables.$color-ch-gray;
}

.error {
  margin-top: 8px;
  color: variables.$color-danger;
}

.itemInput {
  pointer-events: auto;
  margin-right: 10px;
}

.showExpiredButton {
  margin-top: .5rem;
  width: 100%;
  border: none !important;
  border-top: 1px solid #cdcdcd !important;
}
