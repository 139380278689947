.notificationItem {
  display: flex;
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top:8px;
}

.unreadNotification {
  background: rgb(243, 241, 241);
}

.bellIcon:after{
  position: absolute;
  background: red;
  height:12px;
  top:0px;
  right:0px;
  width:12px;
  text-align: center;
  line-height: 2rem;;
  font-size: 1rem;
  border-radius: 50%;
  color:white;
  border:1px solid white;
  margin-left: 30px;
}


.bellIcon:hover {
  cursor: pointer;
}
