@use "@/styles/variables.scss";

.dateButton {
  text-align: left;
}

.dateRange {
  font-size: 11.4px;
  :global {
    .rdrDateDisplayWrapper {
      background-color: white;
    }

    .rdrMonthAndYearWrapper {
      padding: 0;
      height: 40px;
    }
  }
}

.popover {
  border: 2px solid rgb(205, 205, 205);
  max-width: 500px;

  :global {
    .arrow {
      display: none !important;
    }
  }
}

.clearButton {
  text-align: center;
  margin-right: 7px;
  width: 24px;

  & > svg path {
    fill: variables.$color-button-svg;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    & > svg path {
      fill: variables.$color-border-hover;
    }
  }
}
