@use '@/styles/variables.scss';

.editorLayout {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.topRow {
  justify-content: space-between;
  align-items: center;
  padding: 0px 6px;
}

.editorHeader {
  font-weight: variables.$fontweight-semibold;
  font-size: variables.$fontsize-large;
  color: '#0E2956';
}

.actionBarCol {
  display: flex;
  justify-content: flex-end;
  font-size: variables.$fontsize-small;
}

.editorContainer {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.removeIcon {
  min-height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rightColumn {
  min-height: 80vh;
}

.headerRight {
  border-bottom: 1px solid variables.$color-border;
  box-sizing: border-box;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.contractLoadingMessage {
  text-align: center;
  margin-top: 50px;
}

.endorsementsLabel {
  text-transform: none;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: variables.$fontsize-small;
  font-weight: variables.$fontweight-regular;
}

.certificateViewer {
  min-height: 80vh;
}

.editorTopRightContainer {
  margin-left: auto;
  display: inline-flex;
}

.syncButtonContainer {
  margin: 6px;
  cursor: pointer;

  &:hover {
    color: variables.$color-ch-gray !important;
  }
}

.formRow {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formGroup {
  display: block;
  margin-bottom: 21px;

  &.last {
    margin-bottom: 0;
  }
}

.labelLeft,
.labelRight {
  display: flex;
  flex: 1;
  line-height: 30px;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}

.labelRight {
  justify-content: flex-end;
  margin-bottom: 2px;
}

.plusIcon {
  font-size: 15px;
  margin-right: 6px;
  margin-top: 1px;
}

.dialog {
  max-width: 600px;
}

.libraryGrid {
  min-height: 0;
}

.dropdownToggle,
.dropdownToggle button {
  max-height: 32px;
}

.noColumn {
  background-color: red !important;
  width: 0px !important;
  height: 100%;
}

.iconContainer {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.floatContainer {
  min-width: 300px;
  width: 30%;
  height: 60%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.columnClassName {
  min-width: 120px;
  width: calc(100%) !important;
}
