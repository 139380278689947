@use "@/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
}

.label {
  display: inline-block;
}

.labelInfo {
  display: inline-block;
  margin-left: 5px;
}

.subText {
  margin: 0px 0px 4px 0px;
  display: inline-block;
}

.phoneInput {
  margin-left: 10px;
}

.border {
  height: variables.$height-input;
  border-color: variables.$color-border;
  border-style: solid;
  border-radius: 0;
  border-width: 1px;
  padding: 0;
  background-color: variables.$color-content;
  transition: all 110ms variables.$ease-expo;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.invalid {
    border-color: variables.$color-danger;
  }

  svg path {
    fill: variables.$color-button-svg;
  }

  &.focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &.focused:not(.disabled),
  &:focus:not(.disabled),
  &:active:not(.disabled),
  &:hover:not(.disabled) {
    border-color: variables.$color-border-hover;
    box-shadow: variables.$shadow-button;

    svg path {
      fill: variables.$color-border-hover;
    }
  }

  &.disabled input {
    cursor: not-allowed;
  }

  &.hasValue {
    background-color: variables.$color-content;
  }

  &.disabled .input {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #F9F7F7;
  }
}

.iconContainer {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    margin-right: 7px;
  }
  &.right {
    margin-left: 7px;
    margin-right: 3px;
  }
}

.input {
  flex: 1;
  border: 0;
  outline: 0;
  width: 100%;
  height: calc(#{variables.$height-input} - 4px);
  background: transparent !important;
  font-size: variables.$fontsize-normal;
  font-family: variables.$font-body;
  color: variables.$color-readable-dark;
  line-height: 1.4;
  letter-spacing: 0.2px;
  box-shadow: none !important;
  border-radius: 0;

  &[type='date'] {
    padding-right: 6px;

    &::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23848484" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }
  }

  &:hover:not(.disabled) {
    &[type='date'] {
      &::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%235f5f5f" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
      }
    }
  }

  &.focused:not(.disabled),
  &:focus:not(.disabled),
  &:active:not(.disabled) {
    &[type='date'] {
      &::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%234f4f4f" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
      }
    }
  }
}

.error {
  margin-top: 8px;
  color: variables.$color-danger;
}

.prefix {
  border-radius: 0;
  border: 0;
}
