@use "@/styles/variables.scss";
.modal {
}

.modalContent {
  background: variables.$color-content;
  border-radius: 20px;
}

.header {
}

.title {
  color: variables.$color-readable-dark;
  font-weight: variables.$fontweight-medium;
  font-family: variables.$font-title;
  font-size: 24px;
}

.footer {
  margin-bottom: 7px;
}

@media screen and (max-width: variables.$screen-sm-max) {
  .modalContent {
    padding: 0 7px;
  }
}

@media screen and (max-width: variables.$screen-md-max) {
  .title {
    font-size: 22px;
  }
}

@media screen and (min-width: variables.$screen-md-min) and (max-width: variables.$screen-lg-max) {
  .modalContent {
    padding: 7px 14px;
  }
}

@media screen and (min-width: variables.$screen-xl-min) {
  .modalContent {
    padding: 10px 18px;
  }
}

.overFlowVisible {
  overflow: scroll !important;
}
