@use "@/styles/variables.scss";

.dropdown {
  position: absolute;
  left: 0;
  top: 42px;
  width: 100%;
  min-width: 460px;
  z-index: variables.$z-index-6;

  & > div {
    width: 100% !important;
  }

  &.exited {
    pointer-events: none !important;
  }
}

.menu {
  pointer-events: all !important;
  z-index: variables.$z-index-6;
  width: 100%;
  box-shadow: variables.$shadow-modal;
  background-color: variables.$color-content;
  box-sizing: border-box;
  padding: 24px 0 18px 0;
  user-select: contain;
  pointer-events: none;
  border-radius: calc(0.3rem - 1px);
  border: 1px solid variables.$color-border;
}

.inner {
  box-sizing: border-box;
}

.col {
  margin-bottom: 18px;
}

.actions {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.buttonsContainer {
  margin-left: auto;
  display: flex;
}

.expirationFilterContainer {
  display: flex;
}

.expirationFilterText {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #4f4f4f;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-left: 2px;
}

button.resetButton {
  margin-right: 15px;
  font-size: variables.$fontsize-normal;
}

.statusDropdown {
  width: 100%;

  :global(.dropdown),
  button {
    text-transform: none;
    width: 100%;
  }
}

button.clearButton {
  text-align: center;
  margin-right: 7px;
  width: 24px;

  & > svg path {
    fill: variables.$color-button-svg;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    & > svg path {
      fill: variables.$color-border-hover;
    }
  }
}

.inputWithClear {
  padding-right: 0;
}

@media (max-width: variables.$screen-sm-max) {
  .dropdown {
    min-width: auto;
  }
}

@media (max-width: variables.$screen-md-max) {
  .menu {
    max-height: 50vh;
    overflow-x: scroll;
  }
}