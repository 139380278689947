@use "@/styles/variables.scss";

.editor-tabs {
  max-width: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
}
.nav-tabs .nav-item {
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(220, 220, 220);
  margin-left: 2px;
  margin-top: 1px;
  max-width: 25%;
  font-size: variables.$fontsize-button;
  line-height: 1.4;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.nav-tabs .nav-item:first-of-type {
  margin-left: 0;
}
.nav-tabs .nav-item.nav-link.active {
  background-color: rgb(200, 220, 255);
}
