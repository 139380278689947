@use "@/styles/variables.scss";

.dropdown {
  width: 225px;

  :global(.dropdown-menu) {
    width: 225px;
  }
}

.newTag {
  border-radius: 5px;
  background-color: variables.$color-danger;
  color: white;
  padding: 0px 5px;
  margin-right: 10px;
}

.dropdownElement {
  display: flex;
  flex-direction: row;
}
