@use "@/styles/variables.scss";

.label {
  font-family: variables.$font-title;
  font-size: variables.$fontsize-button;
  font-weight: variables.$fontweight-medium;
  color: variables.$color-readable-dark;
  text-transform: uppercase;
  margin-bottom: 4px;
  letter-spacing: 0.4px;
}
