@use '@/styles/variables.scss';

.sidebarContainer {
  width: variables.$sidebar-width-lg;
  background-color: variables.$color-sidebar;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: variables.$z-index-7;
  overflow: hidden;
  overflow-y: scroll;
  user-select: none;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
.collapsedSidebarContainer {
  width: variables.$sidebar-width-collapsed;
}

.logoContainer {
  position: relative;
  width: 90%;
  margin: 14px auto 5px auto;

  span {
    position: inherit !important;
  }

  source,
  img {
    object-fit: contain;
    position: relative !important;
    outline: none !important;
    // height: inherit !important;
  }
}

.buttonGroup {
  width: 100%;
}

.icon {
  position: relative;
  width: 19px;
  height: 20px;
  flex: 1;
  margin-left: 6px;
  min-width: 19px;

  svg {
    object-fit: contain;
  }

  &.bulk {
    padding-right: 1px;
  }

  &.bulk,
  &.language,
  &.endorsements {
    width: 20px;
    min-width: 20px;
    margin-left: 5px;
  }

  &.templates {
    width: 17px;
    min-width: 17px;
    margin-left: 8px;
  }
}

@media screen and (max-width: variables.$screen-md-max) {
  .sidebarContainer {
    width: variables.$sidebar-width-sm;
  }
  .collapsedSidebarContainer {
    width: variables.$sidebar-width-collapsed;
  }
}

@media screen and (min-width: variables.$screen-lg-min) and (max-width: variables.$screen-lg-max) {
  .sidebarContainer {
    width: variables.$sidebar-width-md;
  }
  .collapsedSidebarContainer {
    width: variables.$sidebar-width-collapsed;
  }
}

.logoSM {
  min-width: 45px !important;
  min-height: 45px !important;
  height: 70px !important;
}

.logoMD {
  min-width: 140px !important;
  width: 140px !important;
  height: 70px !important;
}
